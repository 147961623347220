import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginService } from './auth/login.service';
import { SnackbarComponent } from '../shared/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private _loginService: LoginService,
		private _router: Router,
		private _snackBar: MatSnackBar,
  ) { }

  // SNACKBAR --------------------------------------------------------------------

	/**
	 * Opens the Snackbar Component showing the selected message
	 */
	show(
		message: string,
		action: 'close',
		type: 'success' | 'warning',
	) {
		const seconds = message.length > 40 ? message.length / 10 : 4;
		this._snackBar.openFromComponent(SnackbarComponent, {
			data: { message, action, type, seconds },
			panelClass: type,
			horizontalPosition: 'left'
		});
	}

	// ERRORS ---------------------------------------------------------------------

	/**
	 * Uses to display snabackbar errors 
	 * @param err Error object obtained
	 */
	errorHandler( err: any ) {
		console.error(  err );
		let errorMessage = 'Ha ocurrido un error.';
		
		if(err === 'email_taken'){
			errorMessage = 'El correo electronico ya ha sido tomado.'
		}

		this.show(errorMessage, 'close', 'warning');
	}


}

import { MatTableDataSource } from '@angular/material/table';

export type Tabletype = 'badge' | 'object' | 'index' | 'rol' | 'number' | 'text' | 'count' | 'currency' | 'email' | 'link' | 'date' | 'time' | 'phone' | 'status' | 'tag' | 'tags' | 'image';

export interface TableColumn {
	label: string;
	name: string;
	type: Tabletype;
}

export interface TableDataInterface<Type> {
	columns: TableColumn[];
	source?: Type[];
}

export class TableData<Type> {
	columns: TableColumn[] = [];
	source?: MatTableDataSource<Type>;

	constructor(data: TableDataInterface<Type>) {
		this.columns = data.columns;
		this.source = new MatTableDataSource(data.source);
	}

	updateSource(source: Type[]) {
		this.source = new MatTableDataSource(source);
	}
}
